.navbar {
    background-color:none !important;
    padding: 0 0 !important;
    width: 100%;
    padding: 0 ;   
    /* margin-left: -1rem !important;  */
    z-index: 50;
  }
  
  .fixed {
    position: fixed;
    top: 0;
  }
  
  .sticky {
    position: sticky;
  }

  .link{
    text-decoration: none !important;
    font-size: 15px !important;
  }

  .lienNavig:hover{
  color: #ad2000!important;
  background-color: none !important;
}

.lienNavig.active{
  color: #7A1600!important;
  background-color: none !important;
}

.collapse-plus .collapse-title:after {
    position: absolute;
    display: block;
    height: 0.5rem !important;
    width: 0.5rem/* 8px */;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    top: 0.9rem/* 14.4px */;
    left: -1.4rem !important /* 22.4px */;
    content: "+";
    pointer-events: none;
}


.collapse-content {
    visibility: hidden;
    grid-column-start: 1;
    grid-row-start: 2;
    min-height: 0px;
    transition: visibility 0.2s;
    transition: padding 0.2s ease-in-out, background-color 0.2s ease-in-out;
    padding-left: 1rem/* 16px */;
    padding-right: 1rem/* 16px */;
    cursor: unset;
}

  @media (min-width: 768px) {
    .navbar{
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        margin-left: -1rem !important; 
    }
}


@media (min-width:640px) {
    .navbarre {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
        margin-left: 1rem !important; 
    }

    
}