

.p-datepicker {
    padding: none !important;
    background: #ffffff !important;
    color: #495057;
    width: 100% !important;
    border: none !important;
    border-radius: 3px;
}

/* 
.p-datepicker {
    padding: 0.5rem;
    background: #ffffff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 3px;
} */

.p-datepicker-header{
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    background-color: none !important;
}

.p-datepicker .p-datepicker-header {
    padding: 0.5rem;
    color: #ffffff !important;
    background: none !important;
    font-weight: 600;
    margin: 0;
    border-bottom: none !important;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-datepicker-prev{
    color: #ffffff !important;
}
.p-datepicker-month{
    color: #ffffff !important;
}
.p-datepicker-next{
    color: #ffffff !important;
}

.p-datepicker-calendar-container{
    width: 100% !important;
    /* overflow:scroll !important; */
}

.p-datepicker-calendar{
    width: 100% !important;
    height: 20rem !important;
    margin-top: 1rem !important;
}
.p-datepicker-calendar tr{
    text-align: end !important;
    /* background-color: #361db6; */
}

.p-datepicker-calendar td{
    text-align: center !important;
    /* background-color: #20b61d; */
}

.p-datepicker-calendar thead{
    width: 100% !important;
    /* margin-right: 3rem !important; */
    text-align: end !important;
    color: #1db680;

}
.p-datepicker-calendar thead th{
     text-align: center !important;
 
}



.p-datepicker-group-container{
    color: #fff !important;
    margin-top: 0.5rem !important;
    background-color: #017a4f !important;
    padding: 5px 10px !important;
    border-radius: 15px !important;
   
}


.p-datepicker-current-day{
    background-color: #e01414 ;
    border-radius: 20px !important;
}
/* .p-datepicker-today:hover{
    cursor: pointer;
} */

.p-datepicker-today span{
    padding: 2px 4px !important;
    color: #fff !important;
    border:2px solid #364a2e !important;
    background-color: #395435 !important;
    border-radius: 20px !important;
}

.p-datepicker-today span:hover{
    cursor: pointer;
   
}

.p-datepicker-year{
    display: none !important;
}

.p-highlight{
  
    border : 2px solid #0c000a !important;
    background-color: none !important;
    outline: none !important;
    border-radius: 15px !important;
    padding: 2px 5px 5px 5px !important;
    /* cursor: pointer; */
}

.p-datepicker table td > span.p-highlight {
    color: #fff !important;
    background: none !important;
    outline: #e01414 !important;
}

.p-datepicker table td > span:focus {
    outline: none !important ;
    outline-offset: none !important;
    box-shadow: none !important;
}

.disabled-date {
    pointer-events: none;
    color: #999;
}

.MuiAccordionSummary-expandIconWrapper{
    justify-content: end !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 4px;
    box-shadow: none !important;
    position: relative;
    -webkit-transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    overflow-anchor: none;
    border-radius: 0;
}

.gray-bg {
    background-color: gray;
    color: #fff;
  }

@media (max-width: 320px) {
    .p-datepicker-calendar-container{
        width: 100% !important;
        overflow:scroll !important;
    }
}